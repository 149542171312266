<!--
 * @Description: SIM配置
 * @Author: ChenXueLin
 * @Date: 2021-11-04 09:02:34
 * @LastEditTime: 2022-02-11 11:11:59
 * @LastEditors: LiangYiNing
-->
<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      :element-loading-background="loadingBackground"
    >
      <div class="list-box">
        <div class="config config1">
          <div class="til1">卡套餐</div>
          <div class="til2">
            已配置
            <span>{{ simPackageCount }}</span>
            个卡套餐
          </div>
          <el-button type="primary" @click="toConfigPage(1)">
            卡套餐配置
          </el-button>
        </div>
        <div class="config">
          <div class="til1">卡属性</div>
          <div class="til2">
            已配置
            <span>{{ simPropCount }}</span>
            个卡属性
          </div>
          <el-button type="primary" @click="toConfigPage(2)">
            卡属性配置
          </el-button>
        </div>
        <div class="config">
          <div class="til1">卡类型</div>
          <div class="til2">
            已配置
            <span>{{ simTypeCount }}</span>
            个卡类型
          </div>
          <el-button type="primary" @click="toConfigPage(3)">
            卡类型配置
          </el-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import { printError } from "@/utils/util";
import { simConfigCount } from "@/api";

export default {
  name: "SimConfig",
  components: {},
  data() {
    return {
      simPackageCount: 0,
      simPropCount: 0,
      simTypeCount: 0
    };
  },
  mixins: [base],
  computed: {},
  watch: {},
  created() {
    this.getSimConfigCount();
  },
  activated() {
    this.getSimConfigCount();
  },
  methods: {
    async getSimConfigCount() {
      try {
        let res = await simConfigCount();
        if (res.code == "OK") {
          this.simPackageCount = res.data.simPackageCount;
          this.simPropCount = res.data.simPropCount;
          this.simTypeCount = res.data.simTypeCount;
        }
      } catch (error) {
        printError(error);
      }
    },
    toConfigPage(type) {
      if (type == 1) {
        this.routerPush({
          name: "simManage/simPackage",
          params: {
            refresh: true
          }
        });
      } else if (type == 2) {
        this.routerPush({
          name: "simManage/attribute",
          params: {
            refresh: true
          }
        });
      } else {
        this.routerPush({
          name: "simManage/simType",
          params: {
            refresh: true
          }
        });
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.list-box {
  padding-top: 30px;
  box-sizing: border-box;
  .config {
    display: flex;
    align-items: center;
    margin-top: 40px;
    .til1 {
      color: #545456;
      width: 100px;
      text-align: right;
      margin-right: 50px;
    }
    .til2 {
      width: 250px;
      color: #8b8b8b;
    }
  }
  .config1 {
    margin-top: 0px;
  }
}
</style>
